const debounce = require('./utils/debounce');
export default class InViewBase {
  constructor(target, offset) {
    this.targets = Array.from(document.querySelectorAll(target),  e => {return e})
    this.offset = 0
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.windowH = window.innerHeight;
    this.callbackEnter;
  }

  setOffset(n) {
    this.offset = n;
  }

  enter(calback) {
    this.callbackEnter = calback;
    // ロード時
    setTimeout(()=> {
      this.enterAction();
    }, 1000)


    // スクロール時
    window.addEventListener('scroll', ()=> {
      this.enterAction();
    });

    // リサイズ時
    window.addEventListener('resize', debounce(()=> {
      this.windowH = window.innerHeight;
      this.enterAction();
    },400),false);
  }

  enterAction() {
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.targets.forEach( target => {
      var rect = target.getBoundingClientRect();
      var targetTop = rect.top + this.scrollTop;

      if(this.scrollTop + this.windowH > targetTop + this.offset) {
        // ターゲットが見える瞬間
        this.enterEvent(target);
      }
    })
  }

  enterEvent(e) {
    this.callbackEnter(e)
  }

}
