
export default function Header(){
  function fixedHeader(){
    document.getElementsByClassName('l-header')[0].setAttribute('data-fixed', window.pageYOffset > 350 ? 'true' : 'false')
  }
  // function fixedFooterIcn(){
  //    document.getElementsByClassName('l-scrollup')[0].setAttribute('data-fixed', window.pageYOffset > 150 ? '1' : '0')
  //   } 
  window.addEventListener('scroll', ()=> {
    fixedHeader()
  })
  
}
