import anime from 'animejs'
import { NormalParallax, BackgroundParallax } from 'mini-parallax'


export default function(InViewBase) {
  
  slideShow()
  function slideShow (){
    let kv = document.getElementsByClassName('l-index-kv__bg')[0]
    //2個毎にimg要素を切り替えるので変数を二つ用意
    let currentNum1 = 0
    let currentNum2 = 1
    const SPEED = 8000
    
    function playSlideshow() {
      kv.children[currentNum1].className = 'bg js-bg-img'
      kv.children[currentNum2].className = 'bg js-bg-img'
      currentNum1++
      currentNum2++
      if(kv){//ieではkvが取れていなくても発火し続けるので、if文を挟む
        if(currentNum1 > kv.children.length - 1) {
          currentNum1 = 0
          
        }
      }
      if(kv){
        if(currentNum2 > kv.children.length - 1) {
            currentNum2 = 0
        }
      }
        if(kv){
          //is-shownにcssanimationを指定
          kv.children[currentNum1].className = 'is-shown bg js-bg-img'
          kv.children[currentNum2].className = 'is-shown bg js-bg-img'
        }
      
    }
    setInterval(playSlideshow,SPEED)
  }


  //ieではスクロールアニメーションを全カットする為、条件分岐
    let userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      return
    }else{

      //mini-parallaxの指定
      new NormalParallax('.js-parallax')
      new NormalParallax('.js-text-parallax', {
        speed: 0.1,
        isRound: true
      })
      new BackgroundParallax('.js-background-parallax', {
        speed: 0.8,
        isRound: true
      })

      //inViewの指定
      let inView = new InViewBase('.js-inview');
      inView.setOffset(100)

      inView.enter((e)=>{
        if(!e.classList.contains('is-shown')) {
          e.classList.add('is-shown')
          
          if(e.classList.contains('en')) {
            fadeInRead(e)
          }
          if(e.classList.contains('ja-head')) {
            fadeInRead(e)
          }
          if(e.classList.contains('ja-body')) {
            fadeIn(e)
          }
          if(e.classList.contains('thumb')) {
            e.classList.add('is-active')
          }
          if(e.classList.contains('excerpt')) {
            setTimeout(()=>{
              e.classList.add('is-active')
            },50)
            setTimeout(()=>{
              fadeInUp(e.querySelector('.p-btn'))
            },750)
            
          }
          
          if(e.classList.contains('howto__list')) {
            fadeInUp3(e)
          }
          if(e.classList.contains('p-btn')) {
            fadeInUp(e)
          }
          
          if(e.classList.contains('event__body')) {
            fadeInUp(e)
          }
          if(e.classList.contains('sns__list')) {
            fadeInUp(e)
          }
          
        }
      })
    }
  
  //アニメーションの関数たち
  function fadeInRead(elm){
    return  anime({
      targets: elm.querySelectorAll('span'),
      translateY: [100,0],
      duration: 750,
      easing: 'easeOutCubic',
      delay: function(el, i, l) {
        return i * 30;
      }
    })
  }
  
  function fadeIn(elm){
    return  anime({
      targets: elm,        
      opacity: [0,1],
      duration: 650,
      delay: 150,
      easing: 'easeOutCubic',
    })
  }
  function fadeInUp3(elm){
    return  anime({
      targets: elm.querySelectorAll('.item'),        
      opacity: [0,1],
      translateY: [20,0],
      duration: 750,
      easing: 'easeOutCubic',
      delay: function(el, i, l) {
        if(window.isPC){
          return i * 260;
        }else{
          return i * 300;
        }
        
      }
    })
  }
  function fadeInUp(elm){
    return  anime({
      targets: elm,        
      translateY: [20,0],
      opacity: [0,1],
      duration: 1000,
      easing: 'easeOutCubic',
    })
  }

}
