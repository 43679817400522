export default function CurrentNav(){
  PageIn()

  function PageIn(){
    Array.from(document.querySelectorAll('[data-nav-current]'), elm => {
      elm.classList.remove('current')
      
    })
    setTimeout(()=>{
      Array.from(document.querySelectorAll('[data-nav-current]'), elm => {
        let currentPage = elm.getAttribute('data-nav-current')
        let pageId = document.querySelector('.l-page').getAttribute('data-page-id')
        if(pageId == currentPage) {
          elm.classList.add('current')
          return
        }else if(pageId == 'eventdetail'){
          if(elm.getAttribute('data-nav-current') == 'event'){
            elm.classList.add('current')
          }
        }
      })
    },1)
  }
}