import anime from 'animejs'
export default function() {
  let headerLogo = document.getElementsByClassName('l-header__logo')[0]
    let headerLogoImg = document.getElementsByClassName('header__logo')[0]
    let headerNav = document.getElementsByClassName('l-header')[0].querySelector('.l-header__gnav')
    let header = document.getElementsByClassName('l-header')[0].querySelector('.inner')
    let spIcn = document.getElementsByClassName('l-gnav-menu-icon')[0]
    setTimeout(()=>{
      spIcn.classList.add('is-show')
    },350)

    let timeline4 = null
        timeline4 = anime.timeline()
        timeline4
        .add({
          targets: header,
          scaleY: [0,1],
          duration: 450,
          easing: 'easeOutCubic',
        })
        .add({
          targets: headerLogo,
          opacity: [0,1],
          duration: 800,
          easing: 'easeOutCubic',
        })
        .add({
          targets: headerLogoImg,
          opacity: [0,1],
          offset: '-=300',
          duration: 1000,
          easing: 'easeOutCubic',
        })
        .add({
          targets: headerNav,
          opacity:[0,1],
          translateX: [-10,0],
          offset: '-=1500',
          duration: 1200,
          easing: 'easeOutCubic',
        })
  
}