import {TweenLite} from "gsap";
export default function Cursor(){

  
    window.requestAnimationFrame(update);
    window.addEventListener('mousemove', eMouseMove)
  
    // マウス位置
    let mouse = {
      x:0,
      y:0
    };

    // 動かすオブジェクト
    let dot = document.querySelector('.l-cursor')
    // 動かすオブジェクトの位置
    let dotPos = {
      x:0,
      y:0,
      vx:0,
      vy:0
    };

    //マウスを触っていない時に、左上でくっついて表示されてしまうので、その時はnone
    if(dotPos.x == 0 && dotPos.y == 0 && dotPos.vx == 0 && dotPos.vy == 0){
      dot.style.display = "none"
    }

    function update() {

      // 目標値
      // マウス位置
      let tx = mouse.x;
      let ty = mouse.y;

      // 目標値にだんだんと近づける
      let ease = 0.25; // こいつが小さいとよりゆっくりと近くようになる
      dotPos.x += (tx - dotPos.x) * ease;
      dotPos.y += (ty - dotPos.y) * ease;

      TweenMax.set(dot, {
        x:dotPos.x - 10,
        y:dotPos.y - 10,
        scale:1,
        borderWidth:0,
      });

      window.requestAnimationFrame(update);
    }

    //cssでdisplay: noneにしているので、ロード完了後blockへ
    function eMouseMove(e) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
      dot.style.display = "block"
      
    }
    
    //aタグをの上を通る時に円を大きくする、aタグをクリックしたら(pjax)diplaynoneにする
    let links = document.querySelectorAll('.link')
    
    //mouseのwidthとheight
    let mouseW = 20
    let mouseH = 20

    Array.from(links, elm => {
      elm.addEventListener('mouseover',onCursor)
      elm.addEventListener('mouseout',outCursor)
      elm.addEventListener('click',clickCurosor)
    })

    function onCursor() {
      TweenLite.to(dot, 0.3, {
        css: {
          width: mouseW * 2 + 10 + 'px',
          height:  mouseH* 2 + 10 + 'px',
          opacity: '0.4',
        }
      });
      //hoverアニメーションで円を大きくすると中心位置がずれるのでcssで修正
      if(!dot.classList.contains('active')){
        dot.classList.add('active')
      }
    }
    function outCursor() {
      TweenLite.to(dot, 0.3, {
        css: {
          width: mouseW + 'px',
          height: mouseH + 'px',
          opacity: '0.7'
        }
      });
      //hoverアニメーションで円を大きくすると中心位置がずれるのでcssで修正
      if(dot.classList.contains('active')){
        dot.classList.remove('active')
      }
    }
    function clickCurosor(){
      TweenLite.to(dot, 0.3, {
        css: {
          width: mouseW + 'px',
          height: mouseH + 'px',
          display: 'none',
        }
      });
      //hoverアニメーションで円を大きくすると中心位置がずれるのでcssで修正
      if(dot.classList.contains('active')){
        dot.classList.remove('active')
      }
    }
  

}