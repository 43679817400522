import anime from 'animejs'
// import {TweenMax, Power4} from "gsap";

export default function Hamburger(){
  
    const triger = document.getElementsByClassName('l-gnav-menu-icon')[0],
    navMenu = document.getElementsByClassName('l-nav-menu')[0],
    navItems = document.querySelectorAll('.nav-menu__list .item')

    onClickIcn()
    onClickNav()


  function onClickIcn() {
    triger.addEventListener('click',()=>{
      if(!triger.classList.contains('is-open')) {
        triger.classList.add('is-open');
        navMenu.style.transformOrigin = 'bottom'

        let timelineEnter = anime.timeline();
        timelineEnter
          .add({
            targets: navMenu,
            scaleY: [0,1],
            delay: 0,
            easing: 'easeInOutQuad',
            duration: 700,
          })
          .add({
            targets: navItems,
            opacity: [0,1],
            delay: 0,
            easing: 'easeInOutQuad',
            duration: 450,
          })
      }else{
        triger.classList.remove('is-open');
        navMenu.style.transformOrigin = 'top'
        let timelineEnter = anime.timeline();

        timelineEnter
        .add({
          targets: navItems,
          opacity: [1,0],
          delay: 0,
          easing: 'easeInOutQuad',
          duration: 450,
        })
        .add({
          targets: navMenu,
          delay: 0,
          scaleY: [1,0],
          easing: 'easeInOutQuad',
          duration: 500,
        })
    }
  })
  }

  function onClickNav(){
    Array.from(document.querySelectorAll('.nav-menu__list .link'), elm => {
     elm.addEventListener('click', ()=> {
       if(triger.classList.contains('is-open')){
         triger.classList.remove('is-open')
         navMenu.style.transformOrigin = 'bottom'

         let timelineEnter = anime.timeline();
         timelineEnter

         .add({
           targets:  document.querySelectorAll('.nav-menu__list .item'),
           opacity: [1,0],
           easing: 'easeInOutQuad',
           duration: 450,
         })
         .add({
           targets: document.querySelector('.l-nav-menu'),
           delay: 0,
           scaleY: [1,0],
           easing: 'easeInOutQuad',
           duration: 450,
         })
       }
     })
   })
   document.querySelector('.header__logo .link').addEventListener('click', ()=> {
     if(triger.classList.contains('is-open')){
       triger.classList.remove('is-open')
       navMenu.style.transformOrigin = 'top'

       let timelineEnter = anime.timeline();
       timelineEnter

       .add({
         targets:  document.querySelectorAll('.nav-menu__list .item'),
         opacity: [1,0],
         easing: 'easeInOutQuad',
         duration: 450,
       })
       .add({
         targets: document.querySelector('.l-nav-menu'),
         delay: 0,
         scaleY: [1,0],
         easing: 'easeInOutQuad',
         duration: 450,
       })
     }
   })
 }
}
