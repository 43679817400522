import mojs from 'mo-js'
import anime from 'animejs'
export default function() {
  let shape
  
  let timeline1 = null
  timeline1 = anime.timeline()
  let timeline2 = null
  timeline2 = anime.timeline()
  let timeline3 = null
  timeline3 = anime.timeline()

  init()
  startAnimation()
  function init() {
    if(document.getElementsByClassName('index')[0] != null) {
      
      let userAgent = window.navigator.userAgent.toLowerCase()
        if(userAgent.indexOf('firefox') != -1){//firefoxだけ出現位置の調整
          shape = new mojs.Shape({
            parent: document.getElementsByClassName('circle')[0],
            shape: 'circle',  // shape "circle" is default
            radius: 1500,        // shape radius
            x: {100 : 920},
            y: {1300 : 40},
            fill: 'none',
            stroke:'#fff', // or 'cyan'
            strokeWidth: {3000: 1000},
            opacity: {1: 1},
            easing: 'expo.inout',
            duration: 1200,
            isShowStart: true
          })
        }else{
          shape = new mojs.Shape({
            parent: document.getElementsByClassName('circle')[0],
            shape: 'circle',  // shape "circle" is default
            radius: 1500,        // shape radius
            x: {100 : 920},
            y: {100 : 40},
            fill: 'none',
            stroke:'#fff', // or 'cyan'
            strokeWidth: {3000: 1000},
            opacity: {1: 1},
            easing: 'expo.inout',
            duration: 1200,
            isShowStart: true
          })
        }
      
    } 
  
  }

  function startAnimation() {
    if(document.getElementsByClassName('index')[0] != null) {
      showShape();
      setTimeout(()=>{
        showTitle()
      },600);
      setTimeout(()=> {
        showBtn()
      }, 1000);
    } else {
      // トップページ以外のアニメーションの設定
        
        setTimeout(()=>{
          if(document.querySelector('.l-page').getAttribute('data-page-id')=='eventdetail'){
            setTimeout(()=>{
              document.querySelector('.l-kv__bg').classList.add('active')
            },1200)

            timeline3
            .add({
              targets: document.querySelector('.l-kv__read .read'),
              translateY: [20,0],
              opacity: [0,1],
              duration: 650,
              easing: 'easeOutCubic',
            })
            .add({
              targets: document.querySelector('.l-contents'),
              translateY: [20,0],
              opacity: [0,1],
              duration: 750,
              offset: '-=250',
              easing: 'easeOutCubic',
            })
          }else if(document.querySelector('.l-page').getAttribute('data-page-id')=='event'){
            timeline3
            .add({
              targets: document.querySelectorAll('.l-kv__read .read__en span'),
              translateY: [100,0],
              duration: 450,
              easing: 'easeOutCubic',
              delay: function(el, i, l) {
                return i * 30;
              }
            })
            .add({
              targets: document.querySelector('.l-kv__read .read__ja'),
              translateX: [-20,0],
              opacity: [0,1],
              offset: '-=550',
              duration: 750,
              easing: 'easeOutCubic',
            })
            .add({
              targets: document.querySelector('.l-contents .s-event'),
              translateY: [20,0],
              opacity: [0,1],
              offset: '-=450',
              duration: 650,
              easing: 'easeOutCubic',
            })
            .add({
              targets: document.querySelector('.js-kv-svg'),
              opacity: [0,1],
              duration: 650,
              offset: '-=650',
              easing: 'easeOutCubic',
            })
          }else if(document.querySelector('.l-page').getAttribute('data-page-id')=='about'){
            timeline3
            .add({
              targets: document.querySelectorAll('.l-kv__read .read__en span'),
              translateY: [100,0],
              duration: 450,
              easing: 'easeOutCubic',
              delay: function(el, i, l) {
                return i * 30;
              }
            })
            .add({
              targets: document.querySelector('.l-kv__read .read__ja'),
              translateX: [-20,0],
              opacity: [0,1],
              offset: '-=550',
              duration: 750,
              easing: 'easeOutCubic',
            })
            .add({
              targets: document.querySelector('.js-kv-svg'),
              opacity: [0,1],
              duration: 650,
              offset: '-=550',
              easing: 'easeOutCubic',
            })
            .add({
              targets: document.querySelector('.s-intoro .text'),
              translateY: [20,0],
              opacity: [0,1],
              duration: 1000,
              offset: '-=300',
              easing: 'easeOutCubic',
            })
          }else{
          timeline3
          .add({
            targets: document.querySelectorAll('.l-kv__read .read__en span'),
            translateY: [100,0],
            duration: 450,
            easing: 'easeOutCubic',
            delay: function(el, i, l) {
              return i * 30;
            }
          })
          .add({
            targets: document.querySelector('.l-kv__read .read__ja'),
            translateX: [-20,0],
            opacity: [0,1],
            offset: '-=550',
            duration: 750,
            easing: 'easeOutCubic',
          })
          .add({
            targets: document.querySelector('.js-kv-svg'),
            opacity: [0,1],
            duration: 650,
            offset: '-=550',
            easing: 'easeOutCubic',
          })
        }
      },600)
    }
  }

  function showShape() {
    setTimeout(()=>{
      shape.play()
    },250)
  }

  function showTitle() {
    let readEnHead = document.getElementsByClassName('read__en')[0].querySelectorAll('.head span')
    let readEnBody = document.getElementsByClassName('read__en')[0].querySelectorAll('.body span')
    let readJa = document.getElementsByClassName('read__ja')[0]
    
      timeline1
      .add({
        targets: readEnHead,
        translateY: [100,0],
        duration: 350,
        easing: 'easeOutCubic',
        delay: function(el, i, l) {
          return i * 60;
        }
      })
      .add({
        targets: readEnBody,
        translateY: [100,0],
        offset: '-=550',
        duration: 350,
        easing: 'easeOutCubic',
        delay: function(el, i, l) {
          return i * 60;
        }
      })
      .add({
        targets: readJa,
        opacity: [0,1],
        offset: '-=450',
        duration: 400,
        easing: 'easeOutCubic'
      })
  }
  function showBtn() {
    let readBtn = document.getElementsByClassName('l-index-kv__read')[0].querySelector('.read__btn')

    timeline2    
    .add({
      targets: readBtn,
      translateY: [20,0],
      opacity: [0,1],
      duration: 750,
      easing: 'easeOutCubic',
    })
  }

}
