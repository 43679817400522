import anime from 'animejs'
import { NormalParallax} from 'mini-parallax'

export default function(InViewBase) {
  
  //ieではスクロールアニメーションを全カットする為、条件分岐
  let userAgent = window.navigator.userAgent.toLowerCase();
  if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
    return
  }else{

    
    new NormalParallax('.js-parallax')
    let inView = new InViewBase('.js-inview');
    inView.setOffset(100)

    inView.enter((e)=>{
      if(!e.classList.contains('is-shown')) {
        e.classList.add('is-shown')
        
        if(e.classList.contains('js-fadeInUp')) {
          fadeInUp(e)
        }
      }
    })
    
    function fadeInUp(elm){
      return  anime({
        targets: elm,        
        translateY: [20,0],
        opacity: [0,1],
        duration: 1000,
        easing: 'easeOutCubic',
      })
    }
  }
}
