const
UserAgentChecker  = require('./UserAgentChecker.js').default,
ReplaceImg = require('./ReplaceImg.js').default,
Hover = require('./common/Hover.js').default,
SmoothScroll = require('./common/SmoothScroll.js').default,
Polyfills = require('./common/Polyfills.js').default,
Pjax = require('./Pjax.js').default,
Preload = require('./Preload.js').default,
Header = require('./Header.js').default,
InViewBase = require('./InViewBase.js').default,
CurrentNav = require('./CurrentNav.js').default,
Hamburger = require('./Hamburger.js').default,
Cursor = require('./Cursor.js').default




export default class Controller {
  constructor() {

    // .start()
  }

  init() {
    this.onetime()
    this.common()
    this.page()
  }

  onetime() {
    UserAgentChecker()
    Polyfills()
    Hamburger()
    Header()
    Pjax(this)
  }

  common() {
    ReplaceImg()
    Cursor()
    Hover()
    CurrentNav()
    SmoothScroll()
    Preload(this)
  }



  page() {
    const pageId = document.querySelector('.l-page').getAttribute('data-page-id');
    switch (pageId) {
      case 'index': require('../init/Top.js').default(InViewBase); break;
      case 'about': require('../init/About.js').default(InViewBase); break;
      case 'howto': require('../init/Howto.js').default(InViewBase); break;
      default: break;
    }
  }

  pageAfter() {

  }
}
