// https://www.npmjs.com/package/pjax
import _P from 'pjax'
// import Controller from './Controller.js'
const
OPTIONS = {
  currentUrlFullReload: false,
  cacheBust: false,
  timeout: 10000,
  wait: 800,
  debug: false,
}
/*
 * Pjax制御クラス
 */
export default function(controller) {
  init()
  on()

  function init() {
    let pjax = new _P({
      elements: 'a.js-pjax', // default is "a[href], form[action]"
      selectors: ['title', 'meta', '.l-page'],
      currentUrlFullReload: OPTIONS.currentUrlFullReload,
      cacheBust: OPTIONS.cacheBust,
      timeout: OPTIONS.timeout,
      debug: OPTIONS.debug,
      switches: {
        '.l-page': _P.switches.sideBySide,
        '.l-page': function(oldEl, newEl, options, switchesClasses) {

          setTimeout(()=>{
            oldEl.outerHTML = newEl.outerHTML
            this.onSwitch()
          },1000)

        },
      }
    })
  }

  function on() {
    document.addEventListener('pjax:send', sendRequest)
    document.addEventListener('pjax:complete', completeDom)
    document.addEventListener('pjax:success', successRequest)
    document.addEventListener('pjax:error', error)
  }

  function sendRequest() {
    // リクエスト送信時のアニメーション等を設定
    // フェードアウトアニメーション
    let page = document.querySelector('.l-page-wrapper')
    page.style.transition = '0.3s ease'
    page.style.opacity = '0'


    let screen = document.querySelector('.l-screen-transition .bg')
      if(!screen.classList.contains('is-show')){
        screen.classList.add('is-show')
      }
    return
  }

  function completeDom() {
    // DOMが変更された際の処理
    // Preload.js側でフェードインのアニメーションを実施する
    controller.common()
    return
  }

  function successRequest() {
    return
  }

  function error() {
    console.log('Pjax error')
  }
}
