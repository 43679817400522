import anime from 'animejs'
const OpeningAnimation = require('./OpeningAnimation').default
const HeaderAnimation = require('./HeaderAnimation').default

export default function(controller, callbackFileComplete) {
  const
  imgs = document.querySelectorAll('img'),
  bgImgs = document.querySelectorAll('.js-bg-img'),
  bgImgsPC = document.querySelectorAll('.js-bg-img--pc'),
  bgImgsSP = document.querySelectorAll('.js-bg-img--sp')

  const imgArray = []
  setImgArray()
  // console.log(imgArray.length)
  // console.log(imgArray)

  function setImgArray() {
    if (imgs.length > 0 || bgImgs.length > 0 || bgImgsPC.length > 0 || bgImgsSP.length > 0) {
      let count = 0
      for (let i = 0; i < imgs.length; i++) {
        imgArray.push(imgs[i].src)
        count++
      }
      for (let i = 0; i < bgImgs.length; i++) {
        let elm = bgImgs[i]
        let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
        if(img.indexOf('linear-gradient') != -1) continue
        let url = img.replace(/(url\(|\)|")/g, '')
        if(url == 'none' || url == '') continue
        imgArray.push(url)
        count++
      }
      if(window.isPC) {
        for (let i = 0; i < bgImgsPC.length; i++) {
          let elm = bgImgsPC[i]
          let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
          if(img.indexOf('linear-gradient') != -1) continue
          let url = img.replace(/(url\(|\)|")/g, '')
          if(url == 'none' || url == '') continue
          imgArray.push(url)
          count++
        }
      } else {
        for (let i = 0; i < bgImgsSP.length; i++) {
          let elm = bgImgsSP[i]
          let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
          if(img.indexOf('linear-gradient') != -1) continue
          let url = img.replace(/(url\(|\)|")/g, '')
          if(url == 'none' || url == '') continue
          imgArray.push(url)
          count++
        }
      }
      if(imgArray.length == 0) handleComplete();
      loadImg(imgArray)
    } else {
      handleComplete();
    }
  }

  function loadImg() {
    let length = imgArray.length,
    count = 0,
    progress
    for (var i = 0; i < length; i++) {
      const img = new Image();
      img.onload = () => {
        count++
        progress = count/length
        handleFileComplete(progress)
        if (count >= length) handleComplete()
      };
      img.src = imgArray[i];
    }
  }

  function handleComplete() {
    if(window.finishedFirstLoad == undefined) {
    // 初回読み込み時の処理

      // 初回フラグ
      window.finishedFirstLoad = true
      setTimeout(()=>{
        let loadLogo = document.getElementsByClassName('l-load')[0].querySelector('.logo')
        loadLogo.style.transition = '0.3s ease'
        loadLogo.style.opacity = '1'
        setTimeout(()=>{
          let load = document.getElementsByClassName('l-load')[0]
          load.style.transition = '0.3s ease'
          load.style.opacity = '0'
          load.style.zIndex = '-10'
          
          //初回ロード終了後OpeningAnimation()を発火
          OpeningAnimation()
        },900)
      },1)
      setTimeout(()=>{
        //OpeningAnimation()を発火後、headerを呼ぶ
        HeaderAnimation()
      },1600)
      
    } else {
      let screen = document.querySelector('.l-screen-transition .bg')
      if(screen.classList.contains('is-show')){
        screen.classList.remove('is-show')
      }
      let page = document.querySelector('.l-page-wrapper')
      setTimeout(()=>{
        page.style.transition = '0.3s ease'
        page.style.opacity = '1'
      },300)
      //screen-transitionとコンテンツの表示が完了後OpeningAnimation()を発火
      OpeningAnimation()
      if(controller != null) controller.page()
    }


  }

  function handleFileComplete(progress) {
    
    //   if(window.finishedFirstLoad == undefined) {
      
    //   document.getElementsByClassName('bar')[0].style.width = progress * 100 + "%";
      
    // }
  }


}
